import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';

const Container = styled.div`
	width: 90%;
	max-width: 1200px;
	margin: 0 auto;

	a {
		text-decoration: underline;
		color: #fff;

		:hover {
			text-decoration: none;
		}
	}
`;

const Terms = () => (
	<Layout>
		<SEO title="Terms Of Use" />

		<Container>
			<h2>Introduction</h2>
			<p>These website terms of use (hereinafter ‘terms of use’) govern your use of digitalarm.co.uk (hereinafter ‘this website’) and by using this website, you accept these terms of use in full. If you disagree with these terms of use or any part of these terms of use, you must not use this website.</p>
			<p>This website uses cookies and by using this website you consent to Digital Arm Ltd (herein after ‘the Company’) use of cookies in accordance with the terms of the Company’s Privacy Policy which is published on this website.</p>

			<h2>License to use this website</h2>
			<p>Unless otherwise stated, the Company and/or its licensors own the intellectual property rights in this website and material on this website. Subject to the license below, all these intellectual property rights are reserved.</p>
			<p>You may view, download for caching purposes only, and print pages from the website for your own personal use, subject to the restrictions set out below and elsewhere in these terms of use.</p>
			<p>You must not:</p>

			<ul>
				<li>republish material from this website (including republication on another website);</li>
				<li>sell, rent or sub-license material from this website;</li>
				<li>show any material from this website in public;</li>
				<li>reproduce, duplicate, copy or otherwise exploit material on this website for a commercial purpose;</li>
				<li>edit or otherwise modify any material on this website; or</li>
				<li>redistribute material from this website (except for content specifically and expressly made available for redistribution).</li>
			</ul>

			<h2>Acceptable use</h2>
			<p>You must not use this website in any way that causes, or may cause, damage to this website or impairment of the availability or accessibility of this website; or in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity.</p>
			<p>You must not use this website to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other malicious computer software.</p>
			<p>You must not conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or in relation to this website without the Company’s express written consent.</p>
			<p>You must not use this website to transmit or send unsolicited commercial communications.</p>
			<p>You must not use this website for any purposes related to marketing without the Company’s express written consent.</p>

			<h2>Restricted access</h2>
			<p>Access to certain areas of this website may be restricted and the Company reserves the right to restrict access to other areas of this website, or indeed this entire website, at the Company’s sole discretion.</p>
			<p>If the Company provides you with a user ID and password to enable you to access restricted areas of this website or other content or services, you must ensure that the user ID and password are kept confidential.</p>
			<p>The Company may disable your user ID and password in the Company’s sole discretion without notice or explanation.</p>

			<h2>User content</h2>
			<p>In these terms of use, your “User Content” means material (including without limitation text, images, audio material, video material and audio-visual material) that you submit to this website, for whatever purpose.</p>
			<p>Your User Content must not be illegal or unlawful, must not infringe any third party’s legal rights, and must not be capable of giving rise to legal action whether against you or the Company or a third party and in each case under any applicable law.</p>
			<p>You must not submit any User Content to this website that is or has ever been the subject of any threatened or actual legal proceedings or other similar complaint.</p>
			<p>The Company reserves the right to edit or remove any material submitted to this website, or stored on the Company’s servers, or hosted or published upon this website.</p>
			<p>Notwithstanding the Company’s rights under these terms of use in relation to User Content, the Company does not undertake to monitor the submission of such content to, or the publication of such content on this website.</p>

			<h2>No warranties</h2>
			<p>This website is provided “as is” without any representations or warranties, express or implied. The Company makes no representations or warranties in relation to this website or the information and materials provided on this website.</p>
			<p>Without prejudice to the generality of the foregoing paragraph, the Company does not warrant that:</p>

			<ul>
				<li>this website will be constantly available, or available at all; or</li>
				<li>the information on this website is complete, true, accurate or non-misleading; or</li>
				<li>the content provided by any third-party websites that from time-to-time are linked to this website.</li>
			</ul>

			<p>Nothing on this website constitutes, or is meant to constitute a contractual commitment or advice of any kind. If you require advice in relation to any technical, legal or financial matter you should consult an appropriate professional.</p>

			<h2>Limitations of liability</h2>
			<p>The Company will not be liable to you in relation to the contents of, or use of, or otherwise in connection with this website:</p>

			<ul>
				<li>to the extent that the website is provided free-of-charge, for any direct loss;</li>
				<li>for any indirect, special or consequential loss; or</li>
				<li>for any business losses, loss of revenue, income, profits or anticipated savings, loss of contracts or business relationships, loss of reputation or goodwill, or loss or corruption of information or data.</li>
			</ul>

			<p>These limitations of liability apply even if the Company has been expressly advised of any potential loss.</p>

			<h2>Reasonableness</h2>
			<p>By using this website, you agree that the exclusions and limitations of liability set out in these terms of use are reasonable. If you do not think these terms of use are reasonable, you must not use this website.</p>

			<h2>Other parties</h2>
			<p>You accept that, as a Limited Liability entity, the Company has an interest in limiting the personal liability of its officers and employees. You agree that you will not bring any claim against the Company’s officers or employees in respect of any losses you suffer in connection with this website.</p>
			<p>Without prejudice to the foregoing paragraph, you agree that the limitations of warranties and liability set out in these terms of use will protect the Company’s officers, employees, agents, subsidiaries, successors, assigns and sub-contractors as well as the Company.</p>

			<h2>Unenforceable provisions</h2>
			<p>If any provision of these terms of use is, or are found to be unenforceable under applicable laws, that will not affect the enforceability of the other provisions of these terms of use.</p>

			<h2>Indemnity</h2>
			<p>You hereby indemnify the Company and undertake to keep the Company indemnified against any losses, damages, costs, liabilities and expenses (including without limitation legal expenses and any amounts paid by the Company to a third party in settlement of a claim or dispute on the advice of the Company’s legal advisers) incurred or suffered by the Company arising out of any breach by you of any provision of these terms of use, or arising out of any claim that you have breached any provision of these terms of use.</p>

			<h2>Breach of these terms of use</h2>
			<p>Without prejudice to the Company’s other rights under these terms of use, if you breach these terms of use in any way, the Company may take such action as the Company deems appropriate to deal with the breach, including suspending your access to this website, prohibiting you from accessing this website, blocking computers using your IP address from accessing this website, contacting your internet service provider to request that they block your access to this website and/or bringing court proceedings against you.</p>

			<h2>Variation</h2>
			<p>The Company may revise these terms of use from time-to-time. Revised terms of use will apply to the use of this website from the date of the publication of the revised terms of use on this website. Please check this page regularly to ensure you are familiar with the current version of these terms of use.</p>

			<h2>Assignment</h2>
			<p>The Company may transfer, sub-contract or otherwise deal with the Company’s rights and/or obligations under these terms of use without notifying you or obtaining your consent.</p>
			<p>You may not transfer, sub-contract or otherwise deal with your rights and/or obligations under these terms of use.</p>

			<h2>Entire agreement</h2>
			<p>These terms of use, together with the Company’s Privacy Policy constitute the entire agreement between you and the Company in relation to your use of this website, and supersede all previous agreements in respect of your use of this website.</p>

			<h2>Law and jurisdiction</h2>
			<p>These terms of use will and are exclusively governed by the law of England and Wales.</p>
		</Container>
	</Layout>
);

export default Terms;
